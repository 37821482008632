// sass-lint:disable no-color-keywords, hex-length
// Colors
// ==========================================================================

/// @name Color Options
/// @group strawstyl-config
/// @access private
/// @type Map

$colors: (
  colors: (
    brand: (
      primary: #3379FE,
      secondary: #ADC9FF,
      support: #0275d8,
      default: #5bc0de,
      success:  #5cb85c,
      warning: #f0ad4e,
      info: #e86800,
      danger: #e50000
    ),
    cta: (
      cta-1: #f00,
      cta-2: #008000,
      cta-3: #00f
    ),
    greyscale: (
      'white': #fff,
      light-grey: #f2f4f9,
      'grey': #949494,
      dark-grey: #4D4D4D,
      'black': #141414
    ),
    link: (
      link: #3399FF,
      link-secondary: darken(#3399FF, 15%),
    ),
    social: (
      facebook: #3b5998,
      twitter: #55acee,
      google: #dd4b39,
      youtube: #b00,
      pinterest: #cb2027,
      vimeo: #aad450,
      tumblr: #32506d,
      rss: #f60,
      instagram: #125688
    ),
  )
);
