///
// Lanver V1
///

/// Base global page styles

div,
section {
  outline: none;

  &:focus,
  &:hover {
    outline: none;
  }
}
.btn-cta {
  padding: 15px 35px;
  text-decoration: none;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: color(greyscale, "white") !important;
  border-radius: 40px;
  background: lighten(color(brand, primary), 5%);
  font-weight: 600;

  @include box-shadow(0 15px 35px rgba(color(brand, primary), 0.4));

  &:hover {
    background: darken(color(brand, primary), 5%);

    @include box-shadow(0 3px 15px rgba(color(brand, primary), 0.4));
  }

  &:focus {
    background: darken(color(brand, primary), 10%);

    @include box-shadow(0 0 0 rgba(color(brand, primary), 0.1));
  }
}

// Header
header {
  padding: 10px 0;
  @include breakpoint(max-width breakpoint-value(sm)) {
    padding: 5px 0;
  }

  ul {
    display: inline-block;
    float: right;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 10px;
      padding: 16px 10px;
      list-style-type: none;
    }
  }

  .header-link {
    text-decoration: none;
    color: color(greyscale, dark-grey);
    font-weight: 700;

    @include font-size(18px);

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    display: inline-block !important;
    padding-top: 7px;
    max-width: 180px !important;
    position: relative;
    z-index: 1;

    @include breakpoint(max-width breakpoint-value(sm)) {
      max-width: 160px !important;
      padding-top: 0;
    }
  }

  nav {
    display: inline-block;
    float: right;

    @include breakpoint(max-width breakpoint-value(md)) {
      position: absolute;
      right: 0;
    }
  }

  // Mobile Toggle
  .set {
    display: inline-block;
    float: right;

    @include breakpoint(max-width breakpoint-value(md)) {
      background-color: #fff;
    }

    .mobile-toggle {
      float: right;
      margin-right: 10px;

      @extend .clear-button;
      @include breakpoint(sm) {
        display: none !important;
      }

      i {
        padding: 12px;
        color: #e8ecf1;
        border: {
          radius: 4px;
        }
        background-color: color(brand, primary);
      }
    }

    .content {
      @include breakpoint(sm) {
        display: block !important;
      }
      @include breakpoint(max-width breakpoint-value(sm)) {
        padding: 10px 0;
        margin-top: 5px;
        width: 100vw;

        ul {
          width: 100%;
          li {
            display: block;
          }
        }
      }
    }
  }

  .btn-cta {
    @include breakpoint(md) {
      float: right;
    }
    @include breakpoint(max-width breakpoint-value(md)) {
      display: none;
      margin: 0 10px;
      padding: 10px 15px;

      @include font-size(14px);
    }
  }
}

// Hero Banner
.banner {
  height: 90vh;
  min-height: 600px;
  max-height: 655px;
  background: {
    position: bottom center;
    image: url("../../assets/img/banner.svg");
    size: cover;
    repeat: no-repeat;
  }

  @include breakpoint(max-width breakpoint-value(md)) {
    height: 50vh;
    min-height: 450px;
    padding: 25px 0;
  }

  @include breakpoint(max-width breakpoint-value(sm)) {
    text-align: center;
    height: 80vh;
    min-height: 600px;
  }

  .container {
    display: flex;
    min-height: 550px;
  }

  .row {
    width: 100%;
  }

  .tabby {
    text-transform: uppercase;
    font-weight: 600;

    @include font-size(18px);
  }

  h1 {
    margin: 5px 0 20px;
    color: color(greyscale, "white");

    @include breakpoint(max-width breakpoint-value(md)) {
      @include font-size(36px);
    }

    span {
      font-weight: 400 !important;
    }
  }

  h2 {
    margin: 20px 0 25px;
    color: color(greyscale, "white");
    font-weight: 400 !important;
    line-height: 22px;
    @include font-size(24px);
    @include breakpoint(max-width breakpoint-value(md)) {
      @include font-size(18px);
    }
  }

  .banner-link {
    margin-top: 15px;
    color: rgba(color(greyscale, "light-grey"), 0.7);

    @include font-size(14px);
  }

  img {
    @include breakpoint(max-width breakpoint-value(md)) {
      max-width: 300px;
      margin: 0 auto;
      padding-top: 50px;
    }
  }
}

// Full page value prop
.uvp-intro {
  padding-bottom: 100px;

  @include breakpoint(max-width breakpoint-value(sm)) {
    padding-right: 15px;
    padding-left: 15px;
  }

  h2 {
    font-weight: 600;

    @include font-size(46px);
    @include breakpoint(max-width breakpoint-value(md)) {
      @include font-size(36px);
    }
  }

  p {
    max-width: 800px;
    margin: 0 auto;
    font-style: normal;
    line-height: 1.5;

    @include font-size(20px);
  }

  a {
    @include font-size(24px);
  }

  .browser-mockup {
    margin: 15px auto;
    max-width: 800px;
    width: 100%;
  }
}

// Value prop
.uvp {
  @include breakpoint(max-width breakpoint-value(sm)) {
    padding: 0 15px;
  }

  // If content located on left
  &.uvp-left {
    .line {
      background: {
        image: url("../../assets/img/line-left-right.svg");
      }
    }
  }

  // If content located on right
  &.uvp-right {
    .line {
      background: {
        image: url("../../assets/img/line-right-left.svg");
      }
    }
  }
  // Styles the inbetween lines
  .line {
    max-width: 750px;
    min-height: 200px;
    margin: 0 auto;
    background: {
      position: center;

      size: cover;
      repeat: no-repeat;
    }

    @include breakpoint(max-width breakpoint-value(sm)) {
      min-height: 100px;
      background: {
        size: contain;
      }
    }
  }

  h3 {
    margin: 0 0 20px;
    color: color(brand, primary);
  }

  h6 {
    margin: 20px 0;
    color: color(brand, secondary);
    font-weight: 400;
  }

  p {
    margin: 20px 0;
    color: color(greyscale, grey);
    line-height: 1.5;

    @include font-size(16px);
  }
}

// User review section
.user-review {
  margin-bottom: 100px;
  padding: 210px 0 100px;
  background: {
    position: top center;

    image: url("../../assets/img/user-review-bg.svg");
    size: cover;
    repeat: no-repeat;
  }

  @include breakpoint(max-width breakpoint-value(sm)) {
    margin-bottom: 75px;
    padding: 0 25px;
  }

  h2 {
    text-align: center;
    color: color(brand, primary);

    @include breakpoint(max-width breakpoint-value(sm)) {
      margin-top: 0;
      padding-top: 125px;
    }
    @include breakpoint(max-width breakpoint-value(md)) {
      @include font-size(36px);
    }
  }

  // Single review
  .quote {
    padding-bottom: 25px;
    background: {
      position: top left;
      image: url("../../assets/img/quote.svg");
      size: 200px;
      repeat: no-repeat;
    }

    p {
      padding-top: 50px;
      padding-left: 50px;
      font-style: italic;
      line-height: 1.7;

      @include font-size(18px);
    }

    img {
      display: inline-block;
      margin-left: 50px;
    }

    span {
      position: relative;
      top: -17px;
      display: inline-block;
      padding: 0 10px;
      color: color(greyscale, grey);
    }
  }

  .link {
    margin-top: 25px;
    text-align: center;

    @include font-size(20px);
  }
}

// FAQ section
.faq {
  h3 {
    margin-top: 0;

    span {
      color: color(brand, primary);
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 10px;
    color: color(greyscale, grey);
    line-height: 1.5;
    //@include font-size(18px);
  }

  a {
    @extend .pull-right;
  }

  // Accordiation wrapper
  .accord {
    margin-top: 25px;

    .set {
      margin-bottom: 15px;
    }

    button {
      box-sizing: border-box;
      width: 100%;
      color: color(brand, primary);
      border-radius: 4px;
      background-color: #e8ecf1;
      font-weight: 600;

      @extend .clear-button;

      i {
        margin-right: 15px;
        padding: 15px;
        color: #e8ecf1;
        border: {
          top-left-radius: 4px;
          bottom-left-radius: 4px;
        }
        background-color: color(brand, primary);
      }
    }

    .content {
      padding: 25px;
      border: #e8ecf1 4px solid;
      border-radius: 4px;
    }
  }
}

// Footer CTA
.footer-cta {
  margin-top: 100px;
  padding: 100px 0 125px;
  background: {
    position: top center;

    image: url("../../assets/img/flat-banner.svg");
    size: cover;
    repeat: no-repeat;
  }

  @include breakpoint(max-width breakpoint-value(sm)) {
    padding: 50px 0 75px;
  }

  h3 {
    margin-bottom: 20px;
    text-transform: uppercase;
    color: color(greyscale, "white");
  }

  h6 {
    margin-top: 20px;
    color: color(greyscale, "white");
    font-weight: 400;
  }
}

// Footer
footer {
  padding: 25px 0;
  border-top: 2px solid darken(color(greyscale, light-grey), 15%);
  background: color(greyscale, light-grey);

  img {
    max-width: 180px !important;
    display: inline-block;
    float: left;
  }

  ul {
    display: inline-block;
    float: right;
    padding-top: 10px !important;

    li {
      padding: 0 15px;
    }
  }

  .footer-link {
    text-decoration: none;
    color: #6d7e9f;
    font-weight: 700;
  }

  small {
    display: block;
    color: rgba(color(greyscale, grey), 0.5);
  }
}

.browser-mockup {
  border-top: 2em solid rgba(color(greyscale, grey), 0.2);
  box-shadow: 0 0.1em 1em 0 rgba(color(greyscale, grey), 0.2);
  position: relative;
  border-radius: 4px;
}

.browser-mockup:before {
  display: block;
  position: absolute;
  content: "";
  top: -1.25em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #f44;
  box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
}

.browser-mockup.with-tab:after {
  display: block;
  position: absolute;
  content: "";
  top: -2em;
  left: 5.5em;
  width: 20%;
  height: 0em;
  border-bottom: 2em solid white;
  border-left: 0.8em solid transparent;
  border-right: 0.8em solid transparent;
}

.browser-mockup.with-url:after {
  display: block;
  position: absolute;
  content: "";
  top: -1.6em;
  left: 5.5em;
  width: calc(100% - 6em);
  height: 1.2em;
  border-radius: 2px;
  background-color: white;
}

.browser-mockup > * {
  display: block;
}

video {
  width: 100%;
  height: auto;
}
#video1 {
  max-width: 460px;
}
#video2 {
  max-width: 300px;
}
#video3 {
  max-width: 530px;
}
