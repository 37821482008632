/// @name Headings
/// Creates basic heading classes
/// @outputs base haeding and pharagraph classes

@mixin infra-headings() {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    clear: both;
    font:  {
      family: font-family(heading);
    }
  }

  p {
    font:  {
      family: font-family(primary);
    }
  }

  a {
    display: inline-block;
    text-decoration: get('typography, link, text-decoration');
    text-transform: get('typography, link, text-transform');
    color: color(link, link);

    @include transition(0.3s ease all);

    &:focus,
    &:active,
    &:hover {
      color: darken(color(link, link), 20%);
      outline: 0;
    }
  }

  $heading: get('typography, heading');
  $heading-keys: map-keys($heading);

  // Loops through checking what type of heading you would like within your stylesheet -> using fluid-font or using static text headings

  @each $key in $heading-keys {
    $map: map-get($heading, $key);
    $level: nth(nth($map, 1), 2);
    $size-fallback: nth(nth($map, 2), 2);
    $line-fallback: nth(nth($map, 3), 2);
    $weight: nth(nth($map, 4), 2);
    $transform: nth(nth($map, 5), 2);

    #{$key},
    .#{$key} {
      font-weight: $weight;
      @include font-size($size-fallback);
      line-height: $line-fallback;
      @if $transform != none {
        text-transform: $transform;
      }
      @if $key == p {
        margin-bottom: 1.5em;
      }
    }
  }
}