.content {
  display: none;
}

.tabby {
  color: #ffffff;
}

.uvp-intro a {
  font-size: 16px;
}

.user-review {
  h3 {
    text-align: center;
  }
}
